<template>
  <v-card
    tag="form"
    class="filter-dialog-card pt-8">
    <v-card-text>
      <v-row
        justify="end"
        class="mb-1">
        <v-btn
          elevation="0"
          color="brandGrey2--text transparent"
          class="text-capitalize"
          @click="$emit('closeDialog')"
          >Cancel</v-btn
        >
      </v-row>

      <h1>FILTERS</h1>

      <!-- media-type -->
      <div>
        <h2 class="brandGrey2--text font-weight-regular mb-2">Media type</h2>

        <!-- media-type-options -->
        <v-row
          justify="space-between"
          align="center"
          class="mb-2"
          no-gutters>
          <!-- audio-only -->
          <button
            type="button"
            class="up-icon text-capitalize rounded-xl"
            :class="mediaType === 'audio' ? 'selected-media-type' : 'media-type'"
            @click="$emit('setMediaType', 'audio')">
            <div class="white px-10 py-5 rounded-xl">
              <div class="image-container mb-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="mediaType === 'audio'" v-bind:class="'mx-auto'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3 18v-6a9 9 0 1118 0v6" stroke="url(#paint0_linear_440_764)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3v5zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3v5z" stroke="url(#paint1_linear_440_764)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><defs><linearGradient id="paint0_linear_440_764" x1="3" y1="10.5" x2="21" y2="10.5" gradientUnits="userSpaceOnUse"><stop stop-color="var(--v-primaryColor-base)"/><stop offset="1" stop-color="var(--v-secondaryColor-base)"/></linearGradient><linearGradient id="paint1_linear_440_764" x1="3" y1="17.5" x2="21" y2="17.5" gradientUnits="userSpaceOnUse"><stop stop-color="var(--v-primaryColor-base)"/><stop offset="1" stop-color="var(--v-secondaryColor-base)"/></linearGradient></defs></svg>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:class="'mx-auto'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3 18v-6a9 9 0 1118 0v6" stroke="#6D7C82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3v5zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3v5z" stroke="#6D7C82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
              </div>
              <span :class="mediaType === 'audio' ? 'primaryColor--text' : ''">Audio only</span>
            </div>
          </button>

          <!-- video-only -->
          <button
            type="button"
            class="up-icon text-capitalize rounded-xl"
            :class="mediaType === 'video' ? 'selected-media-type' : 'media-type'"
            @click="$emit('setMediaType', 'video')">
            <div class="white px-10 py-5 rounded-xl">
              <div class="image-container mb-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="mediaType === 'video'" v-bind:class="'mx-auto'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 19c-2.3 0-6.4-.2-8.1-.6-.7-.2-1.2-.7-1.4-1.4-.3-1.1-.5-3.4-.5-5s.2-3.9.5-5c.2-.7.7-1.2 1.4-1.4C5.6 5.2 9.7 5 12 5s6.4.2 8.1.6c.7.2 1.2.7 1.4 1.4.3 1.1.5 3.4.5 5s-.2 3.9-.5 5c-.2.7-.7 1.2-1.4 1.4-1.7.4-5.8.6-8.1.6v0z" stroke="url(#paint0_linear_1504_2690)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 15l5-3-5-3v6z" stroke="url(#paint1_linear_1504_2690)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><defs><linearGradient id="paint0_linear_1504_2690" x1="2" y1="12" x2="22" y2="12" gradientUnits="userSpaceOnUse"><stop stop-color="var(--v-primaryColor-base)"/><stop offset="1" stop-color="var(--v-secondaryColor-base)"/></linearGradient><linearGradient id="paint1_linear_1504_2690" x1="10" y1="12" x2="15" y2="12" gradientUnits="userSpaceOnUse"><stop stop-color="var(--v-primaryColor-base)"/><stop offset="1" stop-color="var(--v-secondaryColor-base)"/></linearGradient></defs></svg>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:class="'mx-auto'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 19c-2.3 0-6.4-.2-8.1-.6-.7-.2-1.2-.7-1.4-1.4-.3-1.1-.5-3.4-.5-5s.2-3.9.5-5c.2-.7.7-1.2 1.4-1.4C5.6 5.2 9.7 5 12 5s6.4.2 8.1.6c.7.2 1.2.7 1.4 1.4.3 1.1.5 3.4.5 5s-.2 3.9-.5 5c-.2.7-.7 1.2-1.4 1.4-1.7.4-5.8.6-8.1.6v0z" stroke="#6D7C82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 15l5-3-5-3v6z" stroke="#6D7C82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
              </div>
              <span :class="mediaType === 'video' ? 'primaryColor--text' : ''">Video only</span>
            </div>
          </button>
        </v-row>
      </div>

      <!-- max-time -->
      <div>
        <h2 class="brandGrey2--text font-weight-regular mb-2">Max time</h2>
        <v-slider
          min="0"
          max="60"
          class="mx-0"
          color="primaryColor"
          track-color="offWhite"
          hide-details
          :value="maxTime"
          @input="$emit('setMaxTime', $event)"></v-slider>

        <v-row
          class="mb-4"
          justify="space-between"
          align="center"
          no-gutters>
          <span>No max</span>
          <span class="maxTime primaryColor--text">{{ maxTime }} min</span>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions class="d-block mx-auto">
      <v-btn
        dark
        depressed
        elevation="0"
        class="brand-gradient ma-0 mb-2 py-8 rounded-pill"
        @click="$emit('filterCourses')"
        block
        >APPLY FILTERS</v-btn
      >

      <v-btn
        outlined
        depressed
        elevation="0"
        class="ma-0 py-8 rounded-pill"
        @click="$emit('clearAllFilters')"
        block
        >CLEAR ALL</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
  import CourseFiltersDialogMixin from '@/components/courses/course-filters-dialog/course-filters-dialog/CourseFiltersDialog.mixin.vue';
  export default {
    mixins: [CourseFiltersDialogMixin],
  };
</script>

<style scoped>
  h1 {
    font-size: 14px;
    font-weight: 600;
  }
  h2 {
    font-size: 14px;
    font-weight: 400;
  }
  .v-card__actions {
    width: 85%;
  }
  .maxTime {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    text-transform: uppercase;
  }
  ::v-deep .v-slider {
    margin-inline: 0;
  }
  .filter-dialog-card {
    border-radius: 40px 40px 0px 0px !important;
  }
  .media-type {
    background-color: var(--v-brandGrey2-base);
    padding: 1px;
  }
  .selected-media-type {
    background-image: linear-gradient(to right, var(--v-primaryColor-base), var(--v-secondaryColor-base));
    padding: 2px;
  }
</style>
