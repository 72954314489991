<script>
  export default {
    name: 'course-filters-dialog',
    props: {
      mediaType: {
        type: String,
        required: true,
      },
      maxTime: {
        type: Number,
        required: true,
      },
      isDialogShown: {
        type: Boolean,
      },
    },
    computed: {
      showDialogClass() {
        return this.isDialogShown ? 'show-dialog' : '';
      },
    },
  };
</script>
